import { useId } from 'react';

import dynamic from 'next/dynamic';
import { SwiperSlide } from 'swiper/react';

import { useGlobal } from '@hultafors/snickers/hooks';
import {
  SliderBlockFragment,
  SliderHeroFragment,
} from '@hultafors/snickers/types';

import { HeroBlock } from '../hero-block/hero-block';

import styles from './slider-block.module.scss';

const SliderBlockShared = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.SliderBlockShared),
);

interface SliderBlockProps extends SliderBlockFragment {
  priority?: boolean;
}

export const SliderBlock: React.FC<SliderBlockProps> = ({
  arrows,
  dots,
  delay,
  heroes,
  slideAnimation,
  title,
  priority,
}) => {
  const uid = useId();
  const { global } = useGlobal();

  function heroMapper(hero: SliderHeroFragment, index: number) {
    const priority = index === 0;
    return (
      <SwiperSlide
        className="swiper-slide"
        key={`SliderBlock-${uid}-${hero.id}`}
      >
        <HeroBlock
          id={hero.id}
          title={hero.title}
          doubleColorTitle={hero.doubleColorTitle}
          description={hero.description}
          heroIcon={hero.heroIcon}
          ctaColor={hero.ctaColor}
          ctaText={hero.ctaText}
          ctaLink={hero.ctaLink}
          mobileImage={hero.mobileImage}
          tabletImage={hero.tabletImage}
          imageLoad={hero.imageLoad}
          priority={priority}
          secondCtaLink={hero.secondCtaLink}
          secondCtaText={hero.secondCtaText}
          thirdCtaLink={hero.thirdCtaLink}
          thirdCtaText={hero.thirdCtaText}
        />
      </SwiperSlide>
    );
  }

  return (
    <SliderBlockShared
      blockProps={{
        arrows,
        delay,
        dots,
        slideAnimation,
      }}
      ariaLabelNext={global?.nextLabel || ''}
      ariaLabelPrevious={global?.previousLabel || ''}
      ariaLabelSliderNavigation={global?.sliderNavigationLabel || ''}
      className={styles['root']}
    >
      {heroes.map(heroMapper)}
    </SliderBlockShared>
  );
};
